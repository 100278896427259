<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            class="breadcrumb ProximaNovaRegular"
            style="margin-left: 52px; margin-top: 12px"
          >
            <li>
              <span>Solutions</span>
            </li>
            <li>
              <span>Attribution</span>
            </li>
            <li class="ProximaNovaBold">Data Source</li>
          </ul>
        </div>
        <div class="container-fluid">
          <div>
            <w-tab
              :tabName="activeTab"
              :tabs="tabs"
              @activeTab="changeTab($event)"
            >
            </w-tab>
          </div>
          <div
            class="tab-panel"
            style="margin-top: 36px"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                  padding: 0.75rem 1.5rem;
                  background: transparent;
                  border: 0px !important;
                "
              >
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="false"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                    @addPlatform="addPlatform($event)"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100 my-4">
            <div class="d-flex justify-content-end">
              <w-button
                :buttonLoader="'normal'"
                :buttonText="'Continue'"
                :isDisable="disableConnectors"
                :class="{ resetPasswordLink: disableConnectors }"
                @buttonClicked="nextToKPI"
              >
              </w-button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";

export default {
  props: [],
  components: {
    "w-connector": Connector,
    "w-button": Button,
    "w-tab": Tab,
  },
  data() {
    return {
      isShow: false,
      regionDropdown: [
        { text: "City wise", id: "city wise" },
        { text: "State wise", id: "state wise" },
        { text: "Country wise", id: "country wise" },
      ],

      activeTab: "Digital Media",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      categorizedSelectedConnectors: [],
      tabs: [
        {
          category: "Digital Media",
          tabContent: [
            {
              title: "Pre-loaded",
              connectors: [
                {
                  name: "Path Matics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adclarity",
                  image: "adobe-anal",
                  role: "Adclarity",
                },
                {
                  name: "Nieslen",
                  image: "instagram",
                  role: "Nieslen",
                },
              ],
            },
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
          ],
        },
        {
          category: "CRMs",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "Content",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                {
                  name: "Publishers",
                  image: "appsFlyer",
                  role: "DM Publishers",
                },
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    disableConnectors() {
      if (this.selectedConnectors.length > 0) {
        return false;
      }
      return true;
    },
    isStartNewModal() {
      return this.isShow;
    },
  },
  methods: {
    selectRegion(e) {
      this.selectedRegion = e;
    },
    createPlan() {
      this.$refs.solutionModal.open();
    },
    hideWelcomePage() {
      this.$modal.hide("newWelcomePage");
    },
    showWelcomePage() {
      this.isShow = true;
      this.$modal.show("newWelcomePage");
    },
    orderSubCategoryMethod() {
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        var tabContent = [];
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          if (
            tabContent.find(
              (subTitle) =>
                subTitle.title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
            ) === undefined
          ) {
            tabContent.push({
              title: this.categorizedSelectedConnectors[i].tabContent[j].title,
              connectors: [
                {
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                },
              ],
            });
          } else {
            for (var k = 0; k < tabContent.length; k++) {
              if (
                tabContent[k].title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
              ) {
                tabContent[k].connectors.push({
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                });
              }
            }
          }
          if (
            j ===
            this.categorizedSelectedConnectors[i].tabContent.length - 1
          ) {
            this.categorizedSelectedConnectors[i].tabContent = tabContent;
          }
        }
        if (i === this.categorizedSelectedConnectors.length - 1) {
          this.activeTab = this.categorizedSelectedConnectors[0].category;
        }
      }
    },
    nextToKPI() {
      this.$router.push("/solutions/agile-attribution/data-source/kpi");
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addActiveTab() {},
    addPlatform(obj) {
      var platformobj = obj.obj;
      var title = obj.title;
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          category: this.activeTab,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }
    },
  },
};
</script>
<style scoped>
.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 9;
}
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
.search-box {
  width: 450px;
  height: 60px;
  font-size: 16px;
}
.search-button {
  width: 50px;
  background-color: #050505;
}
/deep/.welcome--modal .vm--modal {
  width: 75% !important;
}
/deep/ .modal-connectors .connector {
  height: 80px;
  width: 243px;
  margin: 11px 11px 11px 0;
}
.welcome {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  align-content: flex-start;
}
.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e8edf5;
}
span.start-from-fresh.mt-4 {
  width: 59px;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.btn-effect-intro-modal {
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 175px !important;
  height: 60px !important;
}
.modal-body {
  overflow-y: scroll;
  width: 525px;
  height: 250px;
  padding: 0;
}
.input-group {
  margin-bottom: 15px;
}
@media screen and (max-width: 320px) {
  .welcome--modal .vm--modal {
    width: 85% !important;
  }
}
@media screen and (max-width: 640px) {
  .welcome--modal .vm--modal {
    width: 85% !important;
  }
  .Welcome-to-Data-Poem {
    text-align: center;
  }
  .search-box {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
  .col-sm-6.col-md-3.offset-md-3.text-right.d-flex.justify-content-end {
    width: 40%;
  }
  .cancel-btn-section {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  p.Welcome-to-Data-Poem {
    text-align: center;
  }
  .kpi-box {
    height: 800px;
  }
  .search-box {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .modal-body {
    overflow-y: scroll;
    width: 100%;
    height: 550px;
    padding: 0 5rem;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
  /deep/.modal-connectors .connector {
    width: 215px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .kpi-box {
    height: 300px;
  }
  .Welcome-to-Data-Poem {
    text-align: center;
  }
  .input-group {
    margin-left: 13rem;
  }
  /deep/.modal-body {
    overflow-y: scroll !important;
    width: 525px;
    height: 250px;
    padding: 0;
    margin-left: 8rem;
  }
}
</style>
